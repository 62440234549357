import { render, staticRenderFns } from "./RealName.vue?vue&type=template&id=1eef7423&scoped=true&"
import script from "./RealName.vue?vue&type=script&lang=js&"
export * from "./RealName.vue?vue&type=script&lang=js&"
import style0 from "./RealName.vue?vue&type=style&index=0&id=1eef7423&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eef7423",
  null
  
)

export default component.exports